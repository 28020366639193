import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Febrero"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/febrero"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/febrero",
        title: "Efemérides - Febrero | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – FEBRERO</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de febrero de 1963: Se fundó el Centro Argentino de Socorros Mutuos, en
      la ciudad de Salta.
    </p>
    <p>
      1 de febrero de 1925: Murió Víctor José Arias, quien había nacido en Salta
      en el año 1888. Fue Ingeniero Civil y docente del Colegio Nacional de
      Salta. Estudió algunos yacimientos arqueológicos de la provincia y logró
      una importante colección de piezas, que después de su muerte, su esposa,
      la donó al MUSEO/ Regional del Norte, que funciona en el Cabildo Histórico
      de Salta. Colaboró con los arqueologos Juan B. Ambrosetti y Eric Boman.
    </p>
    <p>
      1 de febrero de 1991: Se fundó en la ciudad de Salta, el Colegio de la
      Divina Misericordia. Su directora desde la apertura es la señorita Gladys
      Josefina Isasmendi. Imparte enseñanza primaria y secundaria. En el
      secundario se dicta el ciclo básico unificado, con talleres de computación
      y contable.
    </p>
    <p>
      2 de febrero de 1592: El gobernador Ramírez de Velasco decidido a poblar
      el Valle, logró que el capitán Jerónimo Rodríguez Macedo funde la Villa de
      la Nueva Madrid de las Juntas, llamada también Madrid de las Juntas, en
      territorio Salteño, en la margen derecha del río Pasaje, a 22 leguas de
      Salta, 30 de San Miguel y 55 de Santiago, a 3 hacia el sudeste del lugar
      donde el río de las Piedras se junta con el Salado y donde todo los
      vecinos de Esteco tienen sus estancias y haciendas. Esta decisión dejaba a
      trasmano a Esteco Viejo.
    </p>
    <p>
      2 de febrero de 1991: Se inauguró el II Encuentro de Culturas,
      Tincu-Tacky, en Campo Quijano. Organizó la biblioteca popular “General
      Güemes” de Campo Quijano (Salta).
    </p>
    <p>
      3 de febrero de 1813: Fallecimiento de Juan Bautista Cabral, a
      consecuencia de las heridas recibidas en el combate de San Lorenzo, al
      asumir la defensa de josé de San Martín.
    </p>
    <p>
      3 de febrero de 1879:&nbsp;Nació en el departamento de Orán, Salta,
      Benjamín Villafañe, hijo de quien fue teniente Gobernador de Orán y que
      tenía el mismo nombre. Este Benjamín(h) fue gobernador de Jujuy.
      Presidente del Consejo Nacional de Educación. Presidente del Automóvil
      Club Argentino. Publicó varias obras de gran envergadura, entre las que se
      destaca: “Las mujeres de antaño en el norte argentino”. Falleció en Jujuy,
      el 26 de diciembre de 1952.
    </p>
    <p>
      3 de febrero de 1910: Nació en San Fernando, Aquiles Garralda. Radicado
      por muchos años en Cosquín, provincia de Córdoba. Es autor de dos libros
      que hacen la historia del Festival de Cosquín. Está radicado en la ciudad
      de Salta.
    </p>
    <p>
      3 de febrero de 1921: Apareció un aviso en los díarios Salteños sobre la
      necesidad de incorporar 400 operarios para -de inmediato- iniciar los
      trabajos en la construcción del ramal C14.
    </p>
    <p>
      3 de febrero de 1956: Se creó la Dirección de Aeronáutica Provincial de
      Salta.
    </p>
    <p>
      4 de febrero de 1934: Nació en Yuto, provincia de Jujuy, Juan Carlos
      Morizzio, quien desde niño se radicó en la ciudad de Salta. Comerciante
      que está ligado a las entidades sociales de Salta. Socio y dirigente de
      los Pokerianos y del Club El Carboncito. En la actualidad es presidente de
      la Cooperadora Policial, Lacustre y Fluvial del Cabra Corral
      (CO.PO.LA.F.), que trabaja con gran celo por el dique, cuidando
      fundamentalmente su fauna ictícola, combatiendo a los depredadores.
      Interesó con su accionar a otras entidades de Salta y del país.
    </p>
    <p>
      4 de febrero de 1947: Nació en Salta, Ricardo Martínez, quien se desempeñó
      como periodista en diario El Intransigente, y en Canal 11. Fue el
      periodista de Ferinoa desde su inicio. Además fue animador de espectáculos
      folklóricos y autor de letras de canciones.
    </p>
    <p>
      4 de febrero de 1989: El Consejo Directivo del Instituto Güemesiano,
      resolvió crear la Sede Cafayate, iniciando este día sus acciones.
    </p>
    <p>
      4 de febrero de 1990: Murió en Salta, el poeta Antonio Vilariño. Respetado
      por sus críticos por su testimonial actitud ante la vida. Había nacido en
      Galicia, España en 1931 y joven se radicó en nuestro país. En el momento
      de su deceso, su hijo, el ingeniero José Antonio Vilariño, era Ministro de
      Bienestar Social de la provincia de Salta.
    </p>
    <p>
      5 de febrero de 1889: Falleció en Lisboa, Portugal, el doctor Pedro A.
      Pardo, médico Salteño, nacido el 17 de Diciembre de 1829. En el año 1854
      se graduó de médico. Hizo periodismo en Salta junto a José Evaristo
      Uriburu. Actuó en política y ejerció la profesión. Fue elegido Decano de
      la facultad de Medicina en febrero de 1874. En el Gobierno del doctor
      Juárez Celman fue designado Enviado Extraordinario y Ministro
      Plenipontenciario de la Rep. Argentina en el Imperio de Austria- Hungría.
      El 1 de Enero de 1888, el gobierno lo nombró con el mismo cargo para
      Portugal. Sus restos fueron repatriados, recibiendo sepultura en Abril de
      1889. Descolló como médico, diputado, catedrático, escritor, político,
      diplomático.&nbsp;
      <br />5 de febrero de 1950: Nació en Córdoba, Daniel Héctor Olguín.
      Abogado radicado en Salta desde hace mucho tiempo. En la actualidad es el
      secretario de Desarrollo Social de la Provincia de Salta.
    </p>
    <p>
      6 de febrero de 1832: El comandante Luna, unitario, fue derrotado por las
      fuerzas federales, en Lagunillas, provincia de Salta.
    </p>
    <p>
      6 de febrero de 1856: Nació en Salta, Luis Güemes. Hijo de Don Luis Güemes
      y de doña Rosaura Castro, nieto del general Güemes. Estudios primarios y
      secundarios en el Colegio Nacional de Salta. En 1873 se inscribió en la
      Facultad de Madicina de Buenos Aires, terminando sus estudios en 1879.
      Viajó a Europa y estudió en la facultad de Medicina de París. Fue alumno
      de los más prestigiosos tratadistas de la medicina en Europa. Regresó en
      1889 al país. Su éxito fue muy grande. Fue el médico por antonomasia, el
      clínico de mayor renombre de Buenos Aires. Compró el consultorio que era
      del Dr. Cleto Aguirre, en Lavalle 869. En 1895 fue designado Académico de
      medicina. Fue Senador Nacional. Un monumento sobre la pared de la calle
      Córdoba de la antigua Facultad de Medicina perpetúa su memoria. Ese
      monumento se inauguró el 7 de diciembre de 1935, a las 11 de la mañana y
      es obra del escultor Agustín Riganelli. La iniciativa fue de la Academia
      de Medicina, constituida en comisión, adhiriéndose por ley el gobierno de
      la Nación y el de la provincia de Salta, la Universidad Nacional y la
      Facultad de Medicina. Murió el 9 de diciembre de 1927.
    </p>
    <p>
      6 de febrero de 1986: Se fundó la Asociación de Jubilados y Pensionados de
      Rosario de Lerma. Entre los fundadores Ramón Manuel Torres (su primer
      presidente). Milton Collar, Guillermo Aráoz, Octavio Ochoa, Felicidad
      Gómez, Pascuala Beltrán, Agustín Rodríguez y josé N. Fernández. Cuentan
      con local propio en la calle Hipólito Irigoyen Nº 38 de rosario de Lerma,
      en la provincia de Salta, donde funciona un comedor para 100 afiliados,
      consultorio médico, enfermería, academia de danzas, pedicuría, peluquería
      y las oficinas administrativas.
    </p>
    <p>
      6 de febrero de 1995: Falleció en un trágico accidente carretero, en la
      provincia de Córdoba, el músico salteño Roberto Medina, que en esos
      momentos era integrante del conjunto foklórico Los Fronterizos.
    </p>
    <p>
      7 de febrero de 1822:&nbsp;Murió en Salta Francisco de Uriondo, coronel.
      La epopeya gaucha lo unió en el sublime ideal libertario con Güemes y los
      hermanó en la perennidad de la gloria, por haber luchado juntos.
    </p>
    <p>
      8 de febrero de 1785:&nbsp;Nació Martín Miguel Juan de Mata de Güemes.
      Según expresan las prolijas investigaciones realizadas por el Dr. Atilio
      Cornejo, Güemes nació en la casa ubicada en la actual calle balcarce Nº
      51, entre España y Caseros, y desde 1789 hasta la muerte del tesorero
      Güemes Montero, ocurrida en 1807, la familia vivió en la casa de Tejada
      (actual calle España Nº720- 740). Allí funcionaban las Reales Cajas. Era
      esta una casa señorial, con enormes patios, huertas, oficinas, salas,
      dormitorios, dependencias para los sirvientes. Con el correr del tiempo
      este niño se convirtió en pieza fundamental de la independencia
      americana.&nbsp;
      <br />8 de febrero de 1832: Las tropas del gobernador coronel Pablo de
      Latorre, al mando del coronel Pablo Alemán, derrotaron al revolucionario
      coronel Cruz Puch, en Los Cerrillos, provincia de Salta.
    </p>
    <p>
      8 de febrero de 1849:&nbsp;Falleció en Salta, a los 82 años de edad, el
      sacerdote Serapio de la Cuesta. Nació en Salta en 1767. En Paraguay
      ingresó a la Orden de los Mercedarios y logró la ordenación sacerdotal en
      la ciudad de Asunción. Desempeñó funciones dentro de su orden en Buenos
      Aires, Cuyo, Córdoba. Fue trasladado a Salta donde se desempeñó como
      Comendador de la Orden durante 30 años. Fue docente y misionero. Fue
      designado Presidente del Cabildo, precisamente en la jornada del 15 de
      mayo de 1816 cuando el Cabildo Salteño prestó obediencia al Congreso de
      Tucumán.
    </p>
    <p>
      8 de febrero de 1945: Nació en Salta, Juan Carlos Fiorillo. Dirigio la
      revista Tiempo Folklórico. Actualmente preside la SADE, filial Salta.
      Publicó: ” De común acuerdo” (1985); Era otro lugar (1986); Coplas del
      Salteño (1988); Brevedad del infinito (1990); Los Restos Ancentrales
      (1992). Realiza programas radiales de contenido cultural.
    </p>
    <p>
      8 de febrero de 1946: Nació en Salta, Víctor Hugo Lellín. Abogado y poeta.
      Publicó en ediciones compartidas: Estadía poética (1965); Canto a dos
      Voces (1971); Siete poemas por sol (1974); Manos a la obra (1981), libro
      que obtuvo el segundo premio de poesía para Autores inéditos en 1984 y que
      fue editado por la Comisión Bicameral Examinadora de Obras de Autores
      salteños, con ilustraciones de Neri Cambronero.
    </p>
    <p>
      8 de febrero de 1985: El Presidente Raúl Ricardo Alfonsín concurrió a
      Salta para presidir el Acto Cumplido en el Panteón de las Glorias del
      Norte de la República y el Desfile Cívico Militar efectuado frente al
      Monumento al Héroe, en la falda del cerro San Bernardo. Era gobernador
      Roberto Romero.
    </p>
    <p>
      8 de febrero de 1993: Fallecieron los doctores Jvonne Retamozo de Iñiguez
      y Héctor Omar Iñiguez, en un accidente aéreo con el helicóptero que los
      transportaba. Este matrimonio de médicos trabajaban en la campaña de
      erradicación del cólera, cuando los sorprendió la muerte en Pichanal,
      dejando solo a sus hijos María Ximena y Héctor Facundo Iñiguez.
    </p>
    <p>
      9 de febrero de 1785: Fue bautizado en esta Santa Iglesia Matríz de Salta.
      Yo el Cura Rector más antiguo exorcicé, bauticé y puse óleo y crisma a
      Martin Miguel Juan de Matta, criatura de dos días e hijo legítimo de don
      Gabriel de Güemes Montero y doña María Magdalena de Goyechea y la Corte,
      fueron sus padrinos de agua y óleo don Joséf González de Prado, Contador
      Ministro Principal de Real Hacienda y doña María Ignacia Cornejo; y para
      que conste lo firmé. Dr. Gabriel Gómez Recio¨. (Extraído del archivo de la
      parroquia San Juan Bautista de la Merced de la ciudad de Salta: Libro de
      Bautismo Nº 8 de la iglesia Matríz de Salta, folio 57).
    </p>
    <p>
      9 de febrero de 1816: Fue asaltado por los gauchos salteños del coronel
      Martín Miguel de Güemes, el general Martín Rodríguez, por discrepancias de
      política local, huyendo este último rumbo a Buenos Aires. Esto sucedió en
      Angostura de la Ciénaga provincia de Salta.
    </p>
    <p>
      9 de febrero de 1874:&nbsp;Vuelve a ser Rosario de la Frontera, capital
      del departamento.
    </p>
    <p>
      9 de febrero de 1884:&nbsp;Se creó la Primera Contaduría General de la
      Provincia de Salta y se establecieron sus funciones.
    </p>
    <p>
      9 de febrero de 1965: Falleció en Salta, el doctor Luis Diez. Nacido en La
      Florida, Rosario de Lerma, Salta en 1886. Luego de los estudios
      secundarios fue enviado a Estados Unidos donde ingresó a la Universidaad
      de Harvard, donde se graduó de odontólogo en 1910. Vuelto al país, se
      radico en Buenos Aires. Al poco tiempo se instala en Salta el primer
      dentista salteño y el primero en establecerse en esta ciudad. Fue
      Intendente Municipal en el gobierno del doctor Joauín Castellanos. Durante
      el gobierno del doctor Marcelo T. de Alvear se desempeñó como gobernador
      del Territorio Nacional de los Andes y luego reelegido en el segundo
      gobierno de Hipólito Yrigoyen. Fue Diputado y Senador Provincial. Acompañó
      como periodista a don David Michel Torino, en El Intransigente. Fue Jefe
      de Policía.
    </p>
    <p>
      10 de febrero de 1813:&nbsp;Nació en San Salvador de Jujuy, Escolástico
      Zegada. Infatigable y apostólico sacerdote, que se ordenó en Chuquisaca el
      25 de octubre de 1836. Vicario de Jujuy desde el 19 de diciembre de 1838.
      Diputado provincial, gobernador interino, durante la revolución encabezada
      por Santibañez contra el gobernador don Pedro de Castañeda. En 1858 fundó
      el Colegio de Educandas, Colegio de Dolores, hoy Nuestra Señora del
      Huerto. Fundó el Hospital San Roque. Falleció en 1871.
    </p>
    <p>
      10 de febrero de 1923: nació en Salta, Juan José Jacobo Botelli, escritor,
      poeta, músico, pintor, imprentero. Publicó entre otros libros “El canto
      del gallo”, “Poemas”, “Cuentos y Relatos”, “Soloquios y ensayos”, “De la
      tierra y el cielo”, “Apuntes en el diario”, “Coplas y Refranes de Salta”,
      “Juan CArlos Dávalos” (Testimonios salteños), “Los italianos y
      descendientes en Salta”, “Coplas Salteñas”, “La Historia del vino de
      Salta”, “Antología”, “Pajarito Velarde”, “El Zumbido intelectual”,
      “Gallero Viejo y otros cuentos”, “El Diario Sobrevivir” y muchos trabajos
      más. Es autor de la música de “La Felipe Varela” entre tantos temas que le
      diera al cancionero argentino. Realiza permanentemente aportes culturales
      a través del periodismo, colaborando con diario El Tribuno, Canal 11, La
      Gauchita y muchas publicaciones más.
    </p>
    <p>
      10 de febrero de 1954:&nbsp;Nació Liliana Bellone de Gutiérrez, poetisa y
      escritora. Publicó ¨Retorno¨ libro de poemas y ganó el Premio Casa de las
      Américas en Cuba con una novela. Ejerce la docencia, ya que es profesora
      de letras.
    </p>
    <p>
      10 de febrero de 1958:&nbsp;Nació en Tucumán, Leonardo Pablo Mangini.
      Músico y poeta, creador del grupo de cultura Yuchán, junto a Miguel Angel
      Cáseres, Héctor Aníbal Aguirre y Graciela Gonta. Como poeta publicó:
      Lágrimas de Luna, Vientre de Yuchán. Sus cuentos fueron publicados en la
      obra: El Vino Sedicioso.
    </p>
    <p>
      10 de febrero de 1859:&nbsp;Ocurrió el fallecimiento del Obispo Jose
      Eusebio Colombres. Nació en Tucumán y falleció en la misma ciudad. En
      Córdoba recibió el orden sagrado. Fue diputado en el Congreso de Tucumán
      de 1816 por Catamarca. A este Obispo se debe el primer trapiche de madera
      del algarrobo movido por bueyes. El gobierno de la Confederación Argentina
      lo propuso ante la Santa Sede para obispo de Salta. Murió mientras se
      tramitaban las bulas.
    </p>
    <p>
      11 de febrero de 1859: Falleció en Catamarca, el doctor José Eusebio
      Colombres. Fue diputado de Catamarca al Congreso de 1816. El gobierno de
      la Confederación Argentina lo propuso ante la Santa Sede para obispo de
      Salta. Murió mientras se tramitaban las Bulas.
    </p>
    <p>
      11 de febrero de 1889:&nbsp;Falleció en Buenos Aires el doctor Cleto
      Aguirre, quien había nacido en Salta el 26 de abril de 1834. Realizó la
      primaria en Salta. Se doctoró en Medicina el 13 de junio de 1855. Volvió a
      Salta donde ejerció la profesión. Fue Diputado Nacional y luego electo
      Gobernador de Salta. Fue profesor de la Facultad de Medicina en Buenos
      Aires en Oftalmología. El Presidente Sarmiento lo designó Miembro del
      Consejo Nacional de Educación. En 1878 fue elegido por tercera vez
      Diputado Nacional por Salta. Ocupó el Decanato de la Facultad de Medicina.
    </p>
    <p>
      11 de febrero de 1931:&nbsp;Monseñor Campero, Obispo de Salta, dispuso la
      iniciación de una colecta destinada a reconstruir la Iglesia de La Poma y
      demás capillas de la zona afectada por el terremoto, que destruyó gran
      parte del pueblo de La Poma.
    </p>
    <p>
      12 de febrero de 1924:&nbsp;Falleció en Salta, el doctor en Filosofía y
      Teología Máximo Figueroa, quin había nacido en 1857. Publicó un libro en
      Francia y fue propuesto por el Obispo Gregorio Romero, para el cargo de
      Obispo Auxiliar de Salta, pero la muerte no se lo permitió.
    </p>
    <p>
      12 de febrero de 1924: Murió Alberto Mendióroz. nació en Tucumán en 1896.
      A los 15 años fue laureando en los juegos Florales. Se recibió de abogado
      en La Plata. Colaboró en El Argentino y en Intermedio Quinquenal. Colaboró
      con versos y prosas en Revista Universitaria y Atenea, fundada por Rafael
      Arrieta. También colaboró en Nosotros. Fue Juez de Primera Instancia en
      Salta y allí formó parte de un grupo de escritores donde estaban Joaquín
      Castellanos y Juan Carlos Dávalos. Publicó: Horas puras (1915 – poemas);
      La Luz buena del amor (poesía – 1932 Edición Post mortem). Dejó 2 novelas
      inéditas.
    </p>
    <p>
      12 de febrero de 1931: Nació en Campo Quijano, José Vides Bautista.
      Maestro, actor, poeta, conductor de programas radiales culturales.
    </p>
    <p>
      13 de febrero de 1799: Ingresó al 3º Batallón de la VI Compañía del
      Regjmiento Pixo de Buenos Aires, como cadete, el hijo del contador de las
      Reales Cajas, el joven Martín Miguel Juan de Mata Güemes.
    </p>
    <p>
      13 de febrero de 1813: En circunstancias en que el general doctor Manuel
      Belgrano, al mando del ejército del norte, hizo prestar juramento de
      obediencia a la Asamblea General Consituyente, levantó por tercera vez la
      bandera azul-celeste y blanca, en las orillas del río Pasaje (provincia de
      Salta), que desde entonces tomó el nombre de Juramento, ignorando aún que
      para esa fecha el Triunvirato le había ordenado arriar la nueva insignia.
    </p>
    <p>
      13 de febrero de 1818: El teniente José Antonio Ruíz, de las tropas
      patricias argentinas, derrotó a los realistas en Acoyte, provincia de
      Salta. Por error algunos historiadores dan esta fecha como sucedida el 11
      de febrero, pero la exacta es la consignada (13), ya que así lo establecen
      los Partes de Batallas del Archivo General de la Nación.
    </p>
    <p>
      13 de febrero de 1853: Nació en Salta Ramón Benigno Castro. Cuando tenía
      14 años empuñó las armas para defender a Salta de la invasión de Felipe
      Varela. Ocupó importantes cargos educativos y fue Intendente de la
      Municipalidad de la ciudad de San Isidro en la provincia de Buenos Aires.
      Falleció el 10 de enero de 1923.
    </p>
    <p>
      13 de febrero de 1954:&nbsp;Se fundó en Rosario de Lerma, el Club Social y
      Deportivo Massalín y Celasco. Entre los fundadores Pedro Vázquez, Antolín
      Briones, Alfredo Genaro Alvarez, Elizardo Quipildor, Víctor Conrado,
      Silvestre José Cardozo, René Córdoba y Agustín Roig, quien fue su primer
      presidente. Entidad donde se practica fútbol, atletismo, natación,
      básquet. Afiliado a la liga de fútbol del Valle de Lerma, donde obtuvo
      varios campeonatos. Su actual presidente es el señor Rodolfo E. Riesgo.
    </p>
    <p>
      13 de febrero de 1985: Murió en Jujuy, Víctor Abán, destacado periodista y
      hombre de letras que tuvo gran actividad en la ciudad de Salta. Nació en
      Abra-Pampa, en la provincia de Jujuy.
    </p>
    <p>
      14 de febrero de 1819: Se inauguró el templo de Cafayate, cuya
      construcción la había iniciado el presbítero Félix Ignacio Delgado en
      1808.
    </p>
    <p>
      14 de febrero de 1909: Nació en Metán, provincia de Salta, José Francisco
      Badín. Se dedicó a la carrera militar en la Fuerza Aérea. A él se debe la
      construcción del aeropuerto de Esquel y además el primer piloto que
      aterrorizó en ese aeropuerto. Fue director de LADE (Líneas Aéreas del
      Estado). Fue uno de los pioneros del aeropuerto de Jujuy y el primer
      piloto en aterrorizar en dicho aeropuerto. Fue también miembro del
      Tribunal Superior de de la Fuerza Aérea. Falleció en Buenos Aires el 2 de
      diciembre de 1969.
    </p>
    <p>
      14 de febrero de 1962: Se fundó en la ciudad de Salta, Clínica Córdoba.
    </p>
    <p>
      14 de febrero de 1976: Nació en Salta, Sergio Orlando Plaza. Hijo del
      desaparecido periodista Luis Plaza y de Isabel Traghetti, jugador de la
      primera división del Club Gimnasia y Tiro de Salta.
    </p>
    <p>
      15 de febrero de 1788: Nació en Salta, Juan Manuel de Güemes, quien era
      hermano del Héroe. Se doctoró en Derecho en la Universidad de Chuquisaca
      en 1808. Fue varias veces cabildante y gran colaborador de su hermano. Lo
      asesoraba y lo sustituía cuando era gobernador y tenía que ausentarse por
      razones de la guerra. En 1827 fue Teniente Gobernador de Jujuy, cuando
      gobernaba Salta el doctor José Ignacio Gorriti. Murió en Salta el 26 de
      febrero de 1831.
    </p>
    <p>
      15 de febrero de 1811: Nació en San Juan, Domingo Fautino Sarmiento.
      Escribió: Facundo, Recuerdos de Provincia, Argirópolis, Viajes. Fue
      Presidente argentino. Sus padres fueron José Clemente SArmiento y Paula
      Albarracín. Fundó en San Juan el periódico El Zonda. En 1841 es periodista
      en Chile de El mercurio. Fue Senador, ministro de gobierno de Buenos
      Aires, Ministro Plenipotenciario en Chile, Perú y Estados Unidos. Sucedió
      a Mitre en la Presidencia (1868 – 1874). Murió el 11 de setiembre de 1888
      a los 77 años. El Facundo o Civilización y barbarie, su obra más famosa
      (Chile – 1845), (cuenta la vida de Facundo y ataca despiadadamente a
      Rosas). “Mi Defensa”. La vida de Dominguito, Conflíctos y armonías de las
      razas en América (1883).
    </p>
    <p>
      <strong>15 de febrero de 1813</strong>: La vanguardia del ejército
      realista mandada por el general Pío Tristán, fue derrotada por tropas del
      ejército patriota al mando del general doctor Manuel Belgrano, en
      territorio salteño.
    </p>
    <p>
      15 de febrero de 1852: El coronel Juan Crisòstomo Alvarez, unitario, fue
      derrotado por el general Celedonio Gutiérrez, gobernador de Tucumán.
      (Alvarez fue el más gallardo soldado de las luchas contra Rosas, notable
      por su audacia y valor en los combates. Invadió el país procedente de
      Chile, en combinación con Justo José de Urquiza, procurador contribuidor a
      derrocar el gobierno de Rosas en Buenos Aires). A raíz de su derrota
      sucedida en Manantial, provincia de Tucumán, fue hecho prisionero y
      ejecutado dos días más tarde, conjuntamente con Mariano Villagra, Manuel
      Guerra y otros compañeros.
    </p>
    <p>
      15 de febrero de 1942: Nació Gilberto Enrique Oviedo. Ingeniero Civil. Fue
      asesor del Senador Nacional, Dr. Juan Carlos Romero. Fue director del Ente
      Nacional Regulador del Gas. Fue disertante en conferencias y seminarios
      sobre gas en el país y en el exterior. El gobernador Juan Carlos Romero,
      lo designó Ministro de la Producción y El Empleo de la provincia de Salta,
      cargo que ocupa actualmente.
    </p>
    <p>
      15 de febrero de 1945: Creación de la Dirección General de Inmuebles, en
      la provincia de Salta.
    </p>
    <p>
      15 de febrero de 1992: Falleció en Brasil, el ex gobernador, diputado, y
      ex presidente del Partido Justicialista, don Roberto Romero. Desde una
      humilde posición social, llegó a convertirse en uno de los dirigentes más
      importantes de la provincia. Por muchos años fue el director de diario El
      Tribuno. En la actualidad su hijo, el Dr. Juan Carlos Romero, es el
      gobernador de la provincia de Salta.
    </p>
    <p>
      16 de febrero de 1813: La vanguardia realista del ejército de Pío Tristán,
      derrotó a una guerrilla patriota del ejército del general doctor Manuel
      Belgrano, en el Portezuelo, provincia de Salta.
    </p>
    <p>
      16 de febrero de 1834: El hermano del general Martín Miguel de Güemes,
      Isaac de Güemes y Goyechea se casó con Isabel Figueroa y Cornejo y de la
      Corte.
    </p>
    <p>
      16 de febrero de 1913: Se inauguró la obra de agua corriente en Metán.
      Asistió el gobernador Avelino Figueroa, el Ministro de Gobierno Francisco
      M. Uriburu y los miembros de la Comisión Municipal de Metán.
    </p>
    <p>
      16 de febrero de 1915:&nbsp;Falleció en Salta, Victorino Corbalán, quien
      había nacido en Cachi, provincia de Salta en el año 1854. Fue diputado
      provincial y gobernador interino de su provincia en el año 1894. Se dedicó
      al comercio de mulas. Brindó amplio apoyo a Leopoldo Lugones, su pariente,
      cuando este preparaba su obra “La Guerra Gaucha”.
    </p>
    <p>
      17 de febrero de 1807: Por Cédula Real se creó el Obispado de Salta,
      nombrando al doctor Nicolás Videla del Pino, natural de Córdoba, como su
      primer Obispo. La región del Obispado incluía: Salta, San Miguel de
      Tucumán, santiago del Estero, San Ramón de la Nueva orán, Catamarca, San
      Salvador de Jujuy, Tarija y Potosí.
    </p>
    <p>
      17 de febrero de 1807&nbsp;: El Rey de España emite una orden suprema, a
      través de la cual dispuso se pasare a Salta todo lo que existía en la
      Intendencia de Potosí. Con esa medida Potosí y Tarija se quedaban sin
      archivos de su pasado.
    </p>
    <p>
      17 de febrero de 1900: Nació en Campo Santo, José Lo Giúdice. Estudió en
      el Seminario Canciliar de Salta. En 1921 viajó por Europa por cuatro años.
      Realizó estudios en el Consevatorio Nacionale de Nápoli, Italia, teniendo
      como mestro a Leonide Lautario y Antonio Savasta. De regreso al país,
      residió un tiempo en Buenos Aires, después anduvo por Chile y Bolivia.
      Compuso Alma paceña (tango) y Lamento quechua (primera transcripción del
      charango al piano). En Bolivia estudió quechua y aymara. En 1940 retornó a
      Salta donde la emisora LV9 Radio Provincia de Salta lo designó director
      (1946 – 1949), Director de Cultura de la Provincia (1950 – 1952);
      Presidente de la Comisión de Espectáculos Públicos de la Municipalidad de
      Salta (1951). Actuó en Tucumán y Santiago del Estero como jurado. Fue
      gestor de la creación de una escuela oficial de Música de Salta, que lleva
      su nombre.
    </p>
    <p>
      17 de febrero de 1915: Nació en Salta, Cástulo Guerra, quién fue diputado
      provincial, jefe de Policía. Incursionó en las letras publicando «Los ojos
      del Tiempo», entre otras obras.
    </p>
    <p>
      17 de febrero de 1929:&nbsp;Nació en Mendoza, Ennio Pedro Pontussi.
      Ingeniero Agrónomo que fue rector de la Universidad Católica de Salta.
      Publicó un libro sobre la geografía de Salta. Dirigente del Partido
      Renovador de Salta, que fue electo Intendente Municipal, cargo que ocupa
      en el presente.
    </p>
    <p>
      18 de febrero de 1813: El ejército de Apolinario Saravia (a) Chocolate,
      perteneciente al ejército del general doctor Manuel Belgrano, penetrando
      por la Quebrada de Chachapoyas, llegó hasta la hacienda de Castañares,
      situada al norte de la ciudad de Salta.
    </p>
    <p>
      18 de febrero de 1857: El ejército de Apolinario Saravia (a) Chocolate,
      perteneciente al ejército del general doctor Manuel Belgrano, penetrando
      por la Quebrada de Chachapoyas, llegó hasta la hacienda de Castñares,
      situada al norte de la ciudad de Salta.
    </p>
    <p>
      18 de febrero de 1991: Falleció en Salta, Marino Fernádez Molina, quien
      fue un destacado hombre de radio. Fue Jefe de programación de LV9 Radio
      Güemes. Publicista reconocido.
    </p>
    <p>
      19 de febrero de 1813: El ejército al mando del general doctor Manuel
      Belgrano, ya en su totalidad pernoctó en la Hacienda de Castañares,
      ubicada al norte de la ciudad de Salta y prepararon las armas y la
      estrategia para luchar contra el ejército realista al mando del general
      Pio Tristán.
    </p>
    <p>
      20 de febrero de 1813: ¨Batalla de Salta¨. El 20 de febrero los españoles
      fueron tomados entre dos fuerzas. La vanguardia de Belgrano atacó a
      Tristán en el Portezuelo, mientras el grueso de la tropa lo hacía desde
      Castañares. Tristán debió capitular y la generosidad del general triufador
      permitió que los vencidos salieran con los Honores de guerra. Rindieron
      sus armas y se comprometieron a no tomar más parte en la lucha.
    </p>
    <p>
      20 de febrero de 1814: San Martín que ya había reemplazado a Belgrano como
      general en Jefe del Ejército Auxiliar, designó a Güemes Comandante de las
      avanzadas del Río Pasaje.
    </p>
    <p>
      20 de febrero de 1889:&nbsp;Pasando por Barrera Angosta (hoy Mojotoro),
      llegó a la ciudad de Salta el primer ferrocarril procedente de Tucumán.
      Entró por la actual calle Mitre hasta plaza 9 de Julio, con la máquina ¨La
      Salteña¨, dirigida por el maquinista Antonio Saporitti, procediéndose en
      acto solemne a su inauguración y bendición.
    </p>
    <p>
      20 de febrero de 1896: Asumió la gobernación de Salta, Antonio Diaz, en
      reemplazo del doctor Delfín Leguizamón.
    </p>
    <p>
      20 de Febrero de 1901: Asumió la gobernación de Salta, don Angl Zerda, en
      reemplazo de Pio Uriburo.
    </p>
    <p>
      20 de Febrero de 1904: Asumió la gobernación de Salta, el doctor David
      Ovejero, sucediendo a don Angel Zerda.
    </p>
    <p>
      20 de Febrero de 1910:&nbsp;Asumió la gobernación de Salta, don Avelino
      Figueroa.
    </p>
    <p>
      20 de febrero de 1913: Asumió la gobernación de Salta, don Robustiano
      Patrón Costas.
    </p>
    <p>
      20 de febrero de 1921: Se inauguraron las obras del ramal C14, en Rosario
      de Lerma, y para ello se corrió un tren especial desde Salta.
    </p>
    <p>
      20 de febrero de 1923: Nació en Salta, Don Domingo Zorrilla. Docente y
      artista plástico. Reside en Rosario de Lerma este pintor que expuso sus
      cuadros en Salta, Jujuy, Tucumán, Córdoba, Buenos Aires. En sus obras
      aparecen los paisajes de Salta y de Jujuy.
    </p>
    <p>
      20 de febrero de 1931: Se inauguró el Monumento al General Güemes al pie
      del Cerro San Bernardo, obra del artista Víctor Juan Garino. Asistió el
      Presidente Provisional de los argentinos, el general José F. Uriburu.
    </p>
    <p>
      20 de febrero de 1933: Fue inaugurado el camino para automóviles, que une
      la ciudad de Salta, con la cumbre del Cerro San Bernardo.
    </p>
    <p>
      20 de febrero de 1936: Nació en Angastaco, Abraham René Arzelán. Abogado
      con inclinación a la actividad política. Integrante del Club de
      Pokerianos, una entidad que nuclea a un grupo de amigos, desde hace muchos
      años.
    </p>
    <p>
      20 de febrero de 1948: Se inauguró el ferrocarril a Antofagasta.
      Asistieron: Ministro de Obras Públicas de la Nación, el general Juan
      Pistarini; el Alcalde de Antofagasta, Juan de Dios Carmona; el Presidente
      de la República Argentina, Juan Domingo Perón; el gobernador de Salta, don
      Lucio Alfredo Cornejo; Monseñor Roberto José Tavella, arzobispo de Salta y
      el administrador de ferrocarriles del estado capitán de navío (R) Alfredo
      Job.
    </p>
    <p>
      20 de febrero de 1952: Nació en Salta, Humberto Echechurre. Jefe Ecónomo
      de diario El Tribuno. Autor del libro ¨A solas con el Cuchi Leguizamón¨.
    </p>
    <p>
      20 de febrero de 1961: Se fundó el Club 20 de febrero, de Pesca y Caza de
      Metán. Su primer presidente fue Santos Ayuso. En 1972 se agregó el nombre
      Defensores.
    </p>
    <p>
      21 de febrero de 1797: Nació en Salta, María del Carmen Puch, quien se
      casó con Martín Miguel de Güemes. Ese matrimonio tuvo tres hijos, uno de
      los cuales falleció en la infancia. Buscó su muerte al abandonarse
      totalmente, presa de la tristeza que la embargaba y falleció en Rosario de
      la Frontera el 3 de abril de 1822, diez meses después que el héroe gaucho.
    </p>
    <p>
      21 de Febrero de 1826: Nació en Salta, Carlos Duran. Se recibió de Médico
      en 1847. Se especializó en obstreticia. Se radicó en Buenos Aires. Fue
      senador provincial de Buenoos Aires. En 1869 se casó con Amalia Pelliza
      Pueyrredón y a los pocos años perdió a su esposa. Estando viudo renunció a
      la política. con su dinero construyó un hospital en la ciudad de Buenos
      Aires que lleva su nombre. Falleció en Buenos Aires el 7 de agosto de
      1904.
    </p>
    <p>
      21 de febrero de 1944:&nbsp;Nació en Salta, el poeta Hugo Roberto Ovalle.
      Publicó el libro de poemas ¨Distancia del ausente¨ y realizó una antología
      poética de la generación del 60. Compuso letras del cancionero popular
      argentino.
    </p>
    <p>
      21 de febrero de 1953: Nació en Salta, Roberto Eduardo Romero, Director
      del Diario El Tribuno. Empresario visionario responsable del diario de
      mayor tiraje del norte argentino.
    </p>
    <p>
      22 de febrero de 1919:&nbsp;Nació en Salta, Humberto Pedro Burgos.
      Egresado de abogado en la Universidad Nacional de Córdoba en 1950. Ejerció
      la profesión durante 45 años. Fue Fiscal del crimen (1952), Juez del
      Crimen (hasta 1956), Camarista Laboral, Ministro de la Corte de Justicia
      (1983- 1989), Vicepresidente de la Corte por 4 años y Presidente por 2
      años. Fue Jefe de Abogados del Banco Provincial de Salta por concurso.
      Diputado provincial por dos períodos.
    </p>
    <p>
      22 de febrero de 1964:&nbsp;Nació en Salta. Federico Vasvari. La primaria
      la realizó en Animaná y en el Colegio Belgrano, donde también realizó el
      secundario. Se recibió de Médico en la Universidad Nacional de Tucumán.
      Trabajó en la ciudad de Salta, Sanatorio el Parque e Instituto Médico. Fue
      residente por concurso en el Hospital Italiano de Buenos Aires. Fue el
      mejor egresado de su promoción con medalla de oro. Becado en Estados
      Unidos.
    </p>
    <p>
      22 de febrero de 1970: Tomó posesión de su cargo de administrador
      apostólico de la Prelatura de Cafayate, el Reverendo Padre Diego Gutiérrez
      Pedraza.Lo posesionaron el Nuncio de su Santidad Monseñor Lino Zanini y el
      Arzobispo de Salta Carlos Mariano Pérez.
    </p>
    <p>
      22 de Febrero de 1971:&nbsp;Comenzó la reunión en Salta de los obispos del
      Noroeste Argentino. La misma se extendió hasta el 26 de febrero. El tema
      que más se consideró fue la falta de sacerdotes en toda la diócesis.
    </p>
    <p>
      22 de Febrero de 1983:&nbsp;Por renuncia del capitán Ulloa, asumió como
      gobernador de la provincia de Salta, el Contador Público Nacional José
      Edgardo Plaza.
    </p>
    <p>
      22 de febrero de 1992: Falleció el Reverendo Padre Felipe Pelanda López,
      fue socio activo del Instituto Güemesiano. Publicó ¨Ojos Mansos¨, un libro
      en prosa, sobre Cafayate.
    </p>
    <p>
      23 de Febrero de 1841: El coronel Dionisio Puch, al mando de tropas
      salteñas, derrotó a las santiagueñas mandadas por el coronel Lorenzo
      Lugones, en Fragua, provincia de Salta.
    </p>
    <p>
      23 de Febrero de 1870:&nbsp;Zarpó desde la Boca, el vapor Sol Argentino,
      en un viaje de estudio del cauce del rio Bermejo. El vapor quedó varado
      cerca de Rivadavvia, en la provincia de Salta. Luego de solucionar los
      imprevistos suscitados, pudo regresar el vapor, que llegó al puerto de la
      Boca, el 22 de febrero de 1871.
    </p>
    <p>
      23 de frebrero de 1873:&nbsp;Falleció Victorino Solá Martínez de Tineo.
      Nació en Salta en 1976. Fue gobernador delegado en 1826, cuando Arenales
      dirigió una expedición por el Río Bermejo y también lo fue en las
      ausencias del gobernador Felipe Heredia.
    </p>
    <p>
      23 de febrero de 1935:&nbsp;Llegó a su sede el primer arzobispo de Salta,
      monseñor Roberto José Tavella, quien fue consagrado en la catedral de
      Buenos Aires el 17 de febrero. Roberto José Tavella ingresó al aspirantado
      de Bernal, el 15 de diciembre de 1904, cuando tenía 11 años de edad. Tuvo
      a su cargo durante 28 años el gobierno eclesiástico del Arzobispado de
      Salta. Desplegó un gran accionar. Fue propulsor de la Universidad Católica
      de Salta.
    </p>
    <p>
      23 de febrero de 1939: Nació en Salta, el poeta Luis César Andolfi.
      Publicó ¨Canciones a Rosalba¨, ¨Oda al ocio¨, entre otros títulos.
    </p>
    <p>
      23 de febrero de 1996:&nbsp;Rubén Zusman fue elegido para presidir la Liga
      Salteña de Fútbol, por un noveno mandato. Serán 18 años de gobierno.
    </p>
    <p>
      24 de Febrero de 1813:&nbsp;Se celebró un solemne TeDeum en la Catedral de
      Salta con asistencia del General Belgrano, del gobernador intendente,
      miembros del Cabildo y numerosos fieles en adhesión a la Asamblea del
      año13, que se habia declarado Soberana y a la cual todos habian prestado
      juramento de obediencia.
    </p>
    <p>
      24 de febrero de 1873:&nbsp;Falleció en su finca “La Isla”, en la
      provincia de Salta, Maria Josefa de la Corte Arias. Colaboro con la causa
      revolucionaria. Junto a otras damas salteñas preparó el vestuario del
      ejército del norte y para los escuadrones de gauchos del general Martin
      Miguel de Güemes. Era conocida popularmente con el seudónimo de La
      Lunareja. Vinculada con la más alta sociedad de Salta, Orán, Jujuy,
      Tucumán y Catamarca, motivo por el cual lograba importantes recursos para
      asumir su patriotica tarea en la que se hallaba comprometida.
    </p>
    <p>
      24 de febrero de 1929: Nació en Salta, Jaime Solá. Abogado y oficial de
      Estado Mayor Militar retirado con el grado de Teniente Coronel. Estudió en
      Estados Unidos, Italia y en Rusia. Durante 18 años fue profesor titular de
      Derecho Comercial en la Universidad Católica de Salta. Escribió 2 libros:
      “El Día que quisimos cambiar Salta”, y “El Individuo con Libertad y
      Responsabilidad”. En la actualidad es director del Iseis.
    </p>
    <p>
      24 de febrero de 1944: Nació en Orán, Ezequiel Bas Luna. Ha publicado
      numerosos trabajos literarios, obteniendo galardones en todo el Noroeste.
      Está radicado en México, destinado por la orden religiosa a la que
      pertenece.
    </p>
    <p>
      25 de febrero de 1909: Nació en Salta, José Solís Pizarro. Poeta popular,
      que publicó: ¨Atocha tierra mía¨, ¨Cruz de ceniza¨, ¨Cencerro en la
      noche¨. Fue académico de la Real Academia Hispanoamericana de Letras,
      desde setiembre de 1939. Fundó la Voz de Atocha, un periódico nativista.
      Murió el 15 de mayo de 1953.
    </p>
    <p>
      25 de febrero de 1946:&nbsp;Nació en Salta, el escritor y ensayista
      Gregorio Caro Figueroa. Publicó «Historia de la gente decente de Salta».
      Colabora con notas en distintos periódicos y diarioos. Hombre de
      reconocida trayectoria periodística.
    </p>
    <p>
      26 de febrero de 1831:&nbsp;Falleció en Salta Juan Manuel de Güemes,
      hermano del héroe gaucho. Abogado, y doctorado en 1808. Fue teniente de
      gobernador de Jujuy. Había nacido el 15 de febrero de 1788.
    </p>
    <p>
      26 de febrero de 1951:&nbsp;Nació en la Cumbre, provincia de Córdoba,
      Vilma Cristina María Vignoli. Se recibió de Médica Cirujana en 1978. Fue
      Jefa de Cardiología del Hospital San Vicente de Paul de la ciudad de Orán
      en Salta. Jefa de Cardiología del hospital Dr. Arturo Oñativia desde 1987.
      Miembro fundador de la Sociedad de Diabetes y de la Sociedad de
      Cardiología de la Provincia de Salta.
    </p>
    <p>
      26 de febrero de 1976:&nbsp;El Ministro de Economía de la provincia de
      Salta, Dante Armando Lovaglio, se hizo cargo del gobierno de la provincia
      hasta el 1º de marzo de ese año.
    </p>
    <p>
      27 de febrero de 1824:&nbsp;Fue ejecutado en Salta, por orden del
      gobernador general Juan Antonio Alvarez de Arenales, el coronel Sinforoso
      Morales y el coronel Bernardino Olivera, por conspirar la estabilidad del
      gobierno provincial.
    </p>
    <p>
      27 de febrero de 1897:&nbsp;Murió en Rosario de la Frontera, Salta, a los
      39 años de edad, el doctor Martín Gabriel de Güemes, nieto del héroe. Se
      recibió de abogado en Buenos Aires en 1881. A los 28 años fue elegido
      gobernador de Salta. Ejerció un mandato completo desde el 30 de julio de
      1886 hasta el 30 de julio de 1889. Fundó el Banco Provincial de Salta, que
      abrió sus puertas el 10 de enero de 1889. Mandó a construir el Palacio de
      Gobierno (Actual Legislatura), inaugurado en 1889. Después fue elegido
      senador nacional, que ejerció hasta su muerte.
    </p>
    <p>
      28 de febrero de 1841:&nbsp;Nació en Salta, Alejandro Fábregas Mollinedo.
      Luego de los estudios primarios y secundarios en su ciudad natal, ingresó
      a la carrera de las armas. Participó en Cepeda (23 de octubre de 1859) y
      Pavón (17 de setiembre de 1861). En 1864 marchó a la guerra con el
      Paraguay, donde se desempeñó como ayudante del general Paunero. Le valió
      el reconocimiento del Gobierno Argentino y del Brasil con sendas medallas
      de plata. En 1890 alcanzó el grado de Teniente Coronel. Regresó a Salta en
      1899, luego del retiro y falleció en esta ciudad el 4 de marzo de 1900.
    </p>
    <p>
      28 de febrero de 1958: Fueron aprobados los Estatutos de la Asociación de
      Clínicas y Sanatorios Privados de la Provincia de Salta, mediante el
      decreto Nº 13.179 del Gobierno de la intervención federal en la Provincia,
      por el Expediente Nº 5866/58, originado en el Ministerio de Gobierno,
      Justicia e Instrucción Pública.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
